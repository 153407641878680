<template>
  <div class="offer-details">
    <OfferHeaderNavigation></OfferHeaderNavigation>
    <transition :name="routerTransition" mode="out-in">
      <router-view @changeRouteTitle="changeRouteTitle"></router-view>
    </transition>
  </div>
</template>

<script>
import OfferHeaderNavigation from "../../../components/offers-details/HeaderNavigation"
import themeConfig         from '@/../themeConfig.js'

export default {
  name: "OfferDetails",
  components: {
    OfferHeaderNavigation,
    themeConfig
  },
  data() {
    return {
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle || '',
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },

  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle
    },
  }
}
</script>

<style lang="scss">
</style>
